import styled from "styled-components";

import { white, black } from "theme/colors";
import { p, h3 } from "theme/typography";
import { ColorAnim } from "theme/animations";

export const FContainer = styled.footer`
  position: ${(props) => (props.isHome ? "absolute" : "relative")};
  background-color: ${(props) =>
    props.isHome ? "transparent" : white.default};
  bottom: ${(props) => (props.isHome ? "20px" : "0")};
  left: 0;
  width: 100%;
  z-index: 5;
  .container {
    height: ${(props) => (props.isHome ? "unset" : "190px")};
    display: flex;
    flex-direction: row !important;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .col {
    margin-top: 25px;
    padding: 0;
    display: flex;
    width: 100%;
    align-items: center;
    &-smedia {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;
      padding: 0;
      li {
        margin-right: 10px;
        padding: 0;
        a {
          ${p}
          color: ${(props) => (props.isHome ? white.default : black.default)};
        }
      }
    }
  }
  .cta {
    display: flex;
    height: 60px;
    width: 60px;
    position: fixed;

    bottom: 40px;
    right: 20px;
    .samples {
      ${h3};
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 300;
      color: ${white.default};
      color: inherit;
      text-decoration: none;
      transition: 250ms;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 13;
      ${ColorAnim};
      width: 60px;
      height: 60px;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }
  @media (min-width: 769px) {
    flex-wrap: nowrap;
    .col {
      margin-top: 0;
      width: auto;
    }
    .cta {
      display: flex;
      bottom: 30px;
      right: 30px;
      .play {
        display: flex;
      }
      .samples {
        display: flex;
      }
    }
  }
  /* @media (min-width: 1550px) {
    .cta {
      right: 40px;
    }
  } */
`;

export const Logo = styled.div`
  position: relative;
  width: 100%;
  margin: 25px;
  text-align: center;
  a {
    font-size: 120%;
    color: ${black.default};
  }
  @media (min-width: 769px) {
    margin: 0;
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

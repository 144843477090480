import { css } from "styled-components";
import gsap from "gsap";
import { navigate } from "gatsby";

const tl = gsap.timeline();

export const ColorAnim = css`
  animation-name: animation;
  animation-duration: 50s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
  -webkit-animation: animation 50s infinite; /* Safari 4+ */
  -moz-animation: animation 50s infinite; /* Fx 5+ */
  -o-animation: animation 50s infinite;
`;

export const ColorAnimC = css`
  animation-name: animationC;
  animation-duration: 50s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
  -webkit-animation: animationC 50s infinite; /* Safari 4+ */
  -moz-animation: animationC 50s infinite; /* Fx 5+ */
  -o-animation: animationC 50s infinite;
`;

export const goHome = ({ link }) => {
  tl.to(".cape", {
    duration: 1,
    height: "100vh",
    ease: "expo.inOut"
  });
  navigate("/");
};

import React from "react";

import { Link } from "gatsby";

import { Wrapper } from "./AfterFooter.styles";

const AfterFooter = (props) => {
  //PROPS
  const { lang } = props;
  //PROPS
  return (
    <Wrapper>
      <div className="container">
        <p>Copyright © 2021 By Us João & Rigueira, Lda. All rights reserved.</p>

        {lang === "PT" ? (
          <Link to="/politica-de-privacidade">Política de Privacidade</Link>
        ) : (
          <Link to="/en/privacy-policy">Policy</Link>
        )}
      </div>
    </Wrapper>
  );
};

export default AfterFooter;

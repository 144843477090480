import React from "react";

import Ticker from "react-ticker";

import { Pre } from "./PreFooter.styles";

const PreFooter = (props) => {
  return (
    <Pre className="coloredB Tfooter">
      <div className="mk">
        <h4>
          making simple <span>.</span>
        </h4>
      </div>
      <Ticker direction="toRight" mode="chain" speed={1} offset="100%">
        {() => (
          <p className="line">
            web<b>design</b> - landing<b>pages</b> - web<b>apps</b> - marketing
            <b>digital</b> -
          </p>
        )}
      </Ticker>
      <Ticker direction="toLeft" mode="chain" speed={1} offset="-100%">
        {() => (
          <>
            <p className="line">
              <b>arquitectura</b> - reabilitação<b>espaços</b> -{" "}
              <b>serralharia</b> - loja<b>online</b> -
            </p>
          </>
        )}
      </Ticker>
      <Ticker direction="toRight" mode="chain" speed={1} offset="100%">
        {() => (
          <>
            <p className="line">
              gestão<b>redes</b>sociais - design<b>interiores</b> - engenharia
              <b>civil</b> - <b>branding</b> -
            </p>
          </>
        )}
      </Ticker>
      <Ticker direction="toLeft" mode="chain" speed={1} offset="-100%">
        {() => (
          <>
            <p className="line">
              <b>fotografia</b> - <b>vídeo</b> - render<b>3d</b> - modelização
              <b>3d</b> -
            </p>
          </>
        )}
      </Ticker>
    </Pre>
  );
};

export default PreFooter;

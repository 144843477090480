import styled from "styled-components";
import { white, black } from "theme/colors";
import { ColorAnimC } from "theme/animations";

export const Pre = styled.div`
  position: relative;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: ${white.default};
  z-index: 5;
  .mk {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    min-width: 185px;
    text-align: left;
    transform: translate(-50%, -50%);
    h4 {
      line-height: 64px;
      z-index: 2;
      font-weight: bold;
      text-transform: lowercase;
      font-size: 48px;
      line-height: 1.1;
      letter-spacing: 2px;
      span {
        ${ColorAnimC}
      }
    }
  }
  .line {
    opacity: 0.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.5px;
    b {
      letter-spacing: 0.5px;
    }
  }
  @media (min-width: 450px) {
    .mk {
      min-width: 400px;
      text-align: center;
    }
  }
  @media (min-width: 769px) {
    .mk {
      h4 {
        font-size: 64px;
      }
    }
  }
`;
